import React from 'react';
import { Link } from 'gatsby';
import { useSelector, useDispatch } from 'react-redux';
import { postEkyc } from '../../apis';
import { navigateTo } from '../../components/CrossPlatformLink';
import { makeStyles } from '@material-ui/core/styles';
import { createForm } from 'rc-form';
import Helmet from 'react-helmet';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import DoneIcon from '@material-ui/icons/Done';
import Input from '@material-ui/core/Input';
import TableBody from '@material-ui/core/TableBody';
import TableRow from '@material-ui/core/TableRow';
import Layout from '../../components/Layout';
import Table from '../../components/Table';
import Cell from '../../components/Table/cell';

const useStyles = makeStyles(theme => ({
  icon: {
    fontSize: '100px'
  },
  img: {
    maxWidth: '100%'
  },
  remark: {
    whiteSpace: 'pre',
    color: 'black',
    background: '#dbe7f3',
    padding: 10,
    borderRadius: 4
  },
}));


const Form = createForm()(({ onSubmit, form }) => {
  const { validateFields, getFieldDecorator, getFieldError } = form;

  const confirm = () => {
    validateFields(async (error, values) => {
      if (!error) {
        onSubmit(values);
      }
    });
  }
  return (
    <Box p={2}>
      <h2>ยืนยันตัวตนก่อนพบแพทย์</h2>
      <p>เพื่อความปลอดภัยของผู้ใช้บริการและแพทย์ผู้ให้คำปรึกษา ทางเราจำเป็นที่จะต้องยืนยันตัวตนของท่านก่อนพบแพทย์ของเรา</p>
      <Paper>
        <Box p={2}>

          <Table>
            <TableBody>
              <TableRow>
                <Cell>ชื่อจริง</Cell>
                <Cell>
                  {
                    getFieldDecorator('first_name', {
                      rules: [{ required: true }],
                    })(
                      <Input
                        fullWidth
                        error={getFieldError('first_name')}
                      />
                    )
                  }
                </Cell>
              </TableRow>
              <TableRow>
                <Cell>นามสกุล</Cell>
                <Cell>
                  {
                    getFieldDecorator('last_name', {
                      rules: [{ required: true }],
                    })(
                      <Input
                        fullWidth
                        error={getFieldError('last_name')}
                      />
                    )
                  }
                </Cell>
              </TableRow>
              <TableRow>
                <Cell>เบอร์โทร</Cell>
                <Cell>
                  {
                    getFieldDecorator('phone', {
                      rules: [{ required: true }],
                    })(
                      <Input fullWidth type="tel" error={getFieldError('phone')} />
                    )
                  }
                </Cell>
              </TableRow>
            </TableBody>
          </Table>
        </Box>
      </Paper>
      <Box mt={4}>
        <Button
          fullWidth
          color="primary"
          variant="contained"
          onClick={confirm}
        >
          ดำเนินการต่อ
        </Button>
      </Box>
    </Box>
  )
});

export default () => {
  const [result, setResult] = React.useState();
  const { source } = useSelector(state => state.session);

  const submit = async (val) => {
    const res = await postEkyc(val);
    setResult(res);
  }

  return (
    <Layout hideAppBar>
      <Helmet>
        <title>ยืนยันตัวตน KYC - MorOnline</title>
      </Helmet>
      {
        result ? (
          <Box p={2} alignItems="center" display="flex" flexDirection="column">
            <DoneIcon style={{ fontSize: 60 }} />
            <h2>เราได้รับข้อมูลเรียบร้อย</h2>
            <p>ลิ้งสำหรับทำการยืนยันตัวตนจะถูกส่ง SMS ไปยังเบอร์โทรของท่าน</p>
            <Button
              fullWidth
              color="primary"
              variant="contained"
              onClick={() => navigateTo(source, 'me.moronline://exit')}
            >
              ปิดหน้านี้
            </Button>
          </Box>
        ) : (
          <Form onSubmit={submit}/>
        )
      }
    </Layout>
  );
};